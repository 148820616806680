import { create } from 'zustand';
import { CallStoreInterface } from './call-state.interface';

const handleAddAttentionChat = (chatId: string) => (state: CallStoreInterface) => {
  return { attentionChatIds: [...state.attentionChatIds, chatId] };
};

const handleRemoveAttentionChat = (chatId: string) => (state: CallStoreInterface) => {
  return { attentionChatIds: state.attentionChatIds.filter((id) => id !== chatId) };
};

export const useCallStore = create<CallStoreInterface>((set) => ({
  attentionChatIds: [],
  showVideo: false,
  fullScreen: false,
  showChat: false,
  remoteMediaType: 'video',
  websocketConnected: false,
  remoteSoundIcon: false,
  remoteVideoIcon: false,
  virtualBackground: '',
  isManualKYCMode: null,
  // if meeting room Id exists, it means that the user is in a video call
  meetingRoomId: '',
  isVideoRequested: false,
  zoomFullscreen: true,
  isVoiceCall: false,
  photoType: null,
  isOpenModalSummaryConfirm: false,
  showCustomerOfflineAlert: false,
  setShowCustomerOfflineAlert: (showCustomerOfflineAlert: boolean) =>
    set({ showCustomerOfflineAlert }),
  setPhotoType: (photoType: string | null) => set({ photoType }),
  setIsVoiceCall: (isVoiceCall: boolean) => set({ isVoiceCall }),
  showCameraButton: true,
  setShowCameraButton: (showCameraButton: boolean) => set({ showCameraButton }),
  setZoomFullscreen: (zoomFullscreen: boolean) => set({ zoomFullscreen }),
  setRemoteSound: (remoteSoundIcon: boolean) => set({ remoteSoundIcon }),
  setRemoteVideo: (remoteVideoIcon: boolean) => set({ remoteVideoIcon }),
  setRemoteMediaType: (remoteMediaType: string) => set({ remoteMediaType }),
  setShowChat: (showChat: boolean) => set({ showChat }),
  setFullScreen: (fullScreen: boolean) => set({ fullScreen }),
  setShowVideo: (showVideo: boolean) => set({ showVideo }),
  addAttentionChat: (id: string) => set(handleAddAttentionChat(id)),
  removeAttentionChat: (id: string) => set(handleRemoveAttentionChat(id)),
  resetAttentionChat: () => set({ attentionChatIds: [] }),
  setWebsocketConnected: (websocketConnected: boolean) => set({ websocketConnected }),
  setMeetingRoomId: (meetingRoomId: string) => set({ meetingRoomId }),
  setVirtualBackground: (virtualBackground: string) => set({ virtualBackground }),
  setIsManualKYCMode: (isManualKYCMode: boolean) => set({ isManualKYCMode }),
  setIsVideoRequested: (isVideoRequested: boolean) => set({ isVideoRequested }),
  setIsOpenModalSummaryConfirm: (isOpenModalSummaryConfirm: boolean) =>
    set({ isOpenModalSummaryConfirm })
}));
