import { Avatar, Button, Modal } from 'antd';
import { useEffect, useRef, useState } from 'react';
import ChatIcon from '../../../assets/icons/chat';
import VideoIcon from '../../../assets/icons/video';
import { setMeetingRoomId } from '../../../utils/webSocket';
import { CallListInterface } from './call-list.interface';
import { useUserStatusStore } from '../../../store/user-state';
import { RoleEnum } from '../../../static/role';
import { DurationToString } from '../../../utils/timestamp';
import { useTimer } from '../../../hooks/useTimer';
import { ConversationStatusEnum, MessageEventEnum } from '../../../static/message-event';
import {
  CommunicationTypeEnum,
  ConversationContextEnum,
  confirmModalChangeCard,
  defaultCommunicationTypeEnum
} from '../../../static/communication-type';
import { useChatListStore } from '../../../store/chat-list';
import Copy from '../../copy';
import { loadCustomerDetail } from '../../../pages/dashboard/dashboard.helper';
import { useCustomerDetails } from '../../../api/hooks/customer/useCustomerDetails';
import { useWorkspaceSummary } from '../../../api/hooks/workspace/useWorkspaceSummary';
import { useWorkspaceSaveSummary } from '../../../api/hooks/workspace/useWorkspaceSaveSummary';
import { useCallStore } from '../../../store/call-state';
import { useSelectedCustomerStore } from '../../../store/customer-state';
import { CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import useAgentAnswerConv from '../../../api/hooks/inbox/useAgentAnswerConv';
import VoiceCallIcon from '../../../assets/icons/voice-call';
import { StatusKYCEnum } from '../../kyc/kyc.interface';
import KYCStatus from '../../kyc/components/kyc-status';
import { CurrentUserObjectInterface } from '../../../shared/types/user.interface';

export const parseDuration = (
  second: number,
  inactive: boolean,
  startTime: number,
  endTime?: number
) => {
  if (endTime && startTime > endTime) {
    return DurationToString(0);
  }

  if (endTime) return DurationToString(Math.round((endTime - startTime) / 1000));

  if (inactive) return DurationToString(0);

  return DurationToString(second);
};

function CallList(props: CallListInterface) {
  const { data, type, customerName, handleAssignAgent } = props;
  const {
    conversationId,
    startTime,
    endTime,
    inboxName,
    kycStatus,
    agent,
    createdAt,
    conversationStatus,
    inboxConfig
  } = data;
  // eslint-disable-next-line no-console
  const agentName = `${agent?.firstName ?? ''} ${agent?.lastName ?? ''}`;
  const createdAtString = dayjs(createdAt).format('DD/MM/YYYY');
  // convert startTime delay to second based on current time
  const { second } = useTimer(Math.round((Date.now() - startTime) / 1000));
  const { mutate } = useWorkspaceSaveSummary();
  const { fileKTP, fileSelfie } = useSelectedCustomerStore();
  const { userRole } = useUserStatusStore();
  let firstRender = useRef(true);
  const [currentUser] = useState<CurrentUserObjectInterface>(
    JSON.parse(localStorage.getItem('user') || '{}')
  );

  const {
    allChatList,
    selectedCall,
    setSelectedCall,
    setAllChatList,
    unreadMessages,
    clearUnreadMessages
  } = useChatListStore();
  const { setIsManualKYCMode } = useCallStore();
  const { setMetadata } = useSelectedCustomerStore();
  const { mutate: handleAgentAnswerConv } = useAgentAnswerConv();

  const isSelected: boolean | null =
    selectedCall && selectedCall.conversationId === data?.conversationId;
  const inactive = data.event === MessageEventEnum.INACTIVE;
  const [showModal, setShowModal] = useState(false);
  const isAgent = userRole === RoleEnum.AGENT;
  const isResolvedConv = data.conversationStatus === ConversationStatusEnum.RESOLVED;

  useEffect(() => {
    if (isSelected) {
      setMeetingRoomId(data?.meetingRoomId as string);
      if (isAgent && !isResolvedConv && !data?.jawabAt) handleAgentAnswerConv(data?.conversationId);
    }
    // eslint-disable-next-line
  }, [selectedCall?.conversationId]);

  const durationText = parseDuration(second, !!inactive, startTime, endTime);
  const { data: customerDetailsData, isFetched: isFetchedCustomerDetails } = useCustomerDetails(
    data.contactId as string,
    isSelected,
    selectedCall
  );
  const { data: workspaceSummaryData, isFetched: isFetchedWorkspaceSummary } = useWorkspaceSummary(
    data.conversationId as string,
    isSelected,
    selectedCall
  );

  useEffect(() => {
    if (
      isSelected &&
      firstRender.current &&
      isFetchedCustomerDetails &&
      isFetchedWorkspaceSummary
    ) {
      loadCustomerDetail(data, mutate, customerDetailsData, workspaceSummaryData);
      firstRender.current = false;
    }

    // eslint-disable-next-line
  }, [workspaceSummaryData, customerDetailsData, selectedCall]);
  const onConfirmation = async () => {
    const showConfirmModal = fileKTP !== null || fileSelfie !== null;
    showConfirmModal ? setShowModal(true) : onClickHandler();
  };
  const onClickHandler = async () => {
    setShowModal(false);
    const newData = { ...data };
    const isManualKYC = newData?.inboxConfig?.conversationContext === ConversationContextEnum.KYC;

    newData.isSummarySubmitted = false;
    setSelectedCall(newData);
    setIsManualKYCMode(isManualKYC);
    setMeetingRoomId(data?.meetingRoomId as string);
    setMetadata(data?.metadata);

    localStorage.setItem('selectedCall', JSON.stringify(data));
    data.inboxId && clearUnreadMessages(data?.conversationId, data.inboxId);

    const filteredChatlist = allChatList.filter(
      (dataArr) =>
        dataArr.conversationId !== data?.conversationId &&
        dataArr?.event === MessageEventEnum.CREATED
    );
    setAllChatList(filteredChatlist);
  };

  const isVoiceComType = type === CommunicationTypeEnum.VOICE; //  communcation type from inbox config
  const isVideoComType = type === CommunicationTypeEnum.VIDEO; //  communcation type from inbox config
  const isMetaDataComTypeAudioVideo =
    data.metadata?.communicationType === defaultCommunicationTypeEnum.AUDIO_VIDEO; //  communcation type from inbox metadata if user request switch from audio to video
  const isVoiceCall = isVoiceComType || (isVideoComType && isMetaDataComTypeAudioVideo);
  const unreadMessageCount = unreadMessages[data?.conversationId] || 0;
  const showReassignButton =
    userRole === RoleEnum.SUPERVISOR && data.event === MessageEventEnum.ACTIVE && handleAssignAgent;

  return (
    <>
      {unreadMessageCount !== 0 && !isSelected && (
        <div className="absolute -right-2 -top-2 flex h-8 w-8 items-center justify-center rounded-full border-2 border-solid border-orange-danger bg-orange-danger-light font-bold text-white">
          {unreadMessageCount > 9 ? '9+' : unreadMessageCount}
        </div>
      )}
      <div
        onClick={() => {
          onConfirmation();
        }}
        className={`min-h-[210px] cursor-pointer rounded-lg p-4
          ${
            userRole === RoleEnum.SUPERVISOR
              ? 'border border-solid border-grey-50 shadow-lg'
              : inactive
              ? 'bg-grey-20'
              : 'bg-white shadow-lg'
          }
          ${isSelected && 'ring-2 ring-green-600'}`}>
        <div className="flex flex-row items-center">
          <Avatar
            size={48}
            className={`flex h-12 w-12 flex-row justify-center ${
              data.event === MessageEventEnum.ACTIVE
                ? type === CommunicationTypeEnum.VIDEO
                  ? 'bg-blue-glaucaus'
                  : 'bg-green-emerald-light'
                : 'bg-grey-50'
            }`}
            icon={isVoiceCall ? <VoiceCallIcon /> : isVideoComType ? <VideoIcon /> : <ChatIcon />}
          />
          <span className="flex-1 truncate pl-4 text-lg font-semibold">{customerName}</span>
        </div>
        <hr
          className={`my-2 h-px border-none ${
            userRole === RoleEnum.AGENT && !inactive ? 'bg-grey-20' : 'bg-grey-50'
          }`}
        />
        {currentUser?.role === RoleEnum.SUPERVISOR && (
          <KYCStatus
            kycStatus={kycStatus as StatusKYCEnum}
            conversationStatus={conversationStatus as ConversationStatusEnum}
            conversationContext={inboxConfig?.conversationContext as ConversationContextEnum}
          />
        )}
        <div className="truncate text-base font-normal">
          <b>{agentName}</b>
        </div>
        <div className="inline-block text-base font-normal">
          <Copy value={conversationId}>Salin Tiket ID</Copy>
        </div>
        <div className="text-base font-normal">
          {inactive && 'Durasi panggilan '}
          <b>{durationText}</b>
        </div>
        <div className="text-base">{createdAtString}</div>
        {inboxName && (
          <div
            className={`${
              inactive
                ? 'border-grey-80 bg-grey-10'
                : 'border-green-emerald bg-green-10 text-green-emerald'
            } mt-2 rounded-2xl border border-solid text-center`}>
            {inboxName}
          </div>
        )}
        {showReassignButton && (
          <Button
            onClick={() => handleAssignAgent?.(data)}
            className={`mt-2 h-12 w-full rounded-full border-none bg-bold-green text-base font-semibold text-white`}>
            Alihkan Advisor
          </Button>
        )}
      </div>
      <Modal
        title={
          <div className="flex items-center justify-between">
            <h3 className="m-0 p-0 text-2xl font-semibold">{confirmModalChangeCard.title}</h3>
            <div onClick={() => setShowModal(false)}>
              <CloseOutlined />
            </div>
          </div>
        }
        open={showModal}
        closable={false}
        footer={false}
        width={336}>
        <div className="space-y-2">
          <div className="text-md m-0 py-2">{confirmModalChangeCard.description} </div>
          <div className="flex flex-col space-y-3">
            <button
              className="cursor-pointer rounded-3xl border-0 bg-orange-danger py-2 font-sans text-base font-semibold text-white outline-none duration-200 ease-out hover:bg-[#b34307]"
              onClick={() => onClickHandler()}>
              {confirmModalChangeCard.okText}
            </button>
            <button
              className="cursor-pointer rounded-3xl bg-transparent py-2 font-sans text-base font-semibold duration-200 ease-out hover:bg-slate-200"
              onClick={() => setShowModal(false)}>
              {confirmModalChangeCard.cancelText}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CallList;
